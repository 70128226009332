<script>
import Avatar from "../../components/main-avatar/AvatarProfile";
import { showMessage } from "@/js/utils";
import { mapGetters } from "vuex";
export default {
  components: {
    Avatar,
  },
  data() {
    return {
      show: false,
      isLoadingActivity: false,
      isLoadingStatistic: false,
      totals: {},
      activity: [],
      optionsFormat: {
        formatMatcher: "basic",
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "2-digit",
      },
    };
  },
  computed: {
    ...mapGetters("applications", {
      getAppByName: "getAppByName",
    }),
    user() {
      return this.$store.state.user.userProfile;
    },
    showProfile() {
      return Object.keys(this.user || {}).length > 0;
    },
    showStatistics() {
      return (
        Object.keys(this.totals || {}).length > 0 &&
        (["superadmin", "coordinator"].includes(this.$userData.role) || this.$userData._id === this.user.monitorId)
      );
    },
  },
  watch: {
    user() {
      if (!Object.keys(this.user || {}).length) return (this.show = false);
      setTimeout(() => {
        this.show = true;
        this.initData();
      }, 100);
    },
  },
  methods: {
    closeProfile() {
      this.show = false;
      setTimeout(() => {
        this.$store.state.user.userProfile = {};
        this.totals = {};
        this.activity.length = 0;
      }, 500);
    },
    showMessageList(item) {
      return showMessage(item, this.$userData);
    },
    formatDate(date) {
      return this.$global
        .dateLocaleFormat(date, this.optionsFormat)
        .split("de")
        .join(" ");
    },
    async getStatistics() {
      try {
        const token = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);
        if (this.user.role !== "model") return;
        let date = new Date(new Date().setHours(0, 0, 0, 0));
        let toDate = date.toISOString().split("T")[0];
        let fromDate = new Date(date.setDate(date.getDate() - (date.getDay() || 7) + 1)).toISOString().split("T")[0];
        const app = this.getAppByName("statistics");
        const response = `${app.api}/statistics/${this.user.artisticName}?from=${fromDate}&to=${toDate}&city=${this.user.city}`;
        const { data } = await this.$axios.get(response, {
          headers: {
            a1sledom: token,
          },
        });
        const statistics = data.jasmin?.screenName ? data.jasmin : {};
        return statistics?.total || {};
      } catch (error) {
        console.log(error?.message);
      }
    },
    async getTRM() {
      try {
        const token = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);
        const app = this.getAppByName("statistics");
        const response = `${app.api}/statistics/trm?dayValidity=${this.dateISO}`;
        const { data } = await this.$axios.get(response, {
          headers: {
            a1sledom: token,
          },
        });
        this.TRM = +data;
      } catch (e) {
        console.log("e", e);
      }
    },
    totalUSD(money) {
      return this.$global.formatPrice({ currency: "USD", value: money });
    },
    async getActivity() {
      try {
        const today = new Date(new Date().setHours(0, 0, 0, 0)).toISOString().split("T")[0];
        const { data } = await this.$axios.get(
          `/notifications/?type=all&dateFrom=${today}&dateTo=${today}&page=0&users=${this.user.user}&artisticName=${this.user.artisticName}&limit=10`
        );

        return data?.allNotify || [];
      } catch (error) {
        console.log(error);
      }
    },
    async initData() {
      [this.isLoadingStatistic, this.isLoadingActivity] = [true, true];
      this.totals = await this.getStatistics();
      this.isLoadingStatistic = false;
      this.activity = await this.getActivity();
      this.isLoadingActivity = false;
    },
  },
};
</script>

<template>
  <section :class="['modalProfile', show && 'modalProfile--active', !showProfile && 'modalProfile--block']">
    <div class="modalProfile__user">
      <div class="modalProfile__header">
        <div class="modalProfile__title">
          <h2>Información de cuenta</h2>
          <button class="modalProfile__close" @click="closeProfile">
            <iconic class="modalProfile__icon" name="close" />
          </button>
        </div>
        <Avatar :user="user" />
      </div>
      <div class="modalProfile__body">
        <div class="modalProfile__statistics">
          <h3 class="modalProfile__sectionTitle">Producción</h3>
          <p class="modalProfile__sectionSubtitle">Esta semana</p>
          <div class="modalProfile__statisticsValues" v-if="showStatistics">
            <div class="modalProfile__statisticsItem">
              <h4 class="modalProfile__statisticsTitle">Horas en línea</h4>
              <div class="modalProfile__statisticsValue">
                <iconic class="modalProfile__statisticsIcon" name="clock_empty" />
                <span>{{ $global.getTimeFromSeconds(totals.workTime.value) }}</span>
              </div>
            </div>
            <div class="modalProfile__statisticsItem">
              <h4 class="modalProfile__statisticsTitle">Promedio por hora</h4>
              <span>{{ totalUSD(totals.averageEarningPerHour.value) }}</span>
            </div>
            <div class="modalProfile__statisticsItem">
              <h4 class="modalProfile__statisticsTitle">Total ganancia</h4>
              <span>{{ totalUSD(totals.earnings.value) }}</span>
            </div>
          </div>
          <div v-else-if="!showStatistics && !isLoadingStatistic">
            <p>Estadisticas no disponibles.</p>
          </div>
          <div v-if="isLoadingStatistic && !showStatistics" class="spinner"></div>
        </div>
        <div class="modalProfile__activity">
          <h3 class="modalProfile__sectionTitle">Actividad</h3>
          <div class="modalProfile__activityValues" v-if="(activity || []).length">
            <div class="modalProfile__activityItem" v-for="notify in activity" :key="notify._id">
              <span class="modalProfile__activityDate">{{ formatDate(notify.date) }}</span>
              <div class="modalProfile__activityData" v-html="showMessageList(notify)"></div>
            </div>
          </div>
          <div class="modalProfile__activityValues" v-if="!isLoadingStatistic && !isLoadingActivity && !(activity || []).length">
            <span class="modalProfile__activityDate">Sin actividad hoy.</span>
          </div>
          <div v-if="isLoadingActivity" class="spinner"></div>
        </div>
      </div>
    </div>
    <div :class="['modalProfile__mask', show && 'modalProfile__mask--active']" @click="closeProfile"></div>
  </section>
</template>

<style lang="scss">
.modalProfile {
  @include Flex(row, flex-end);
  position: fixed;
  top: 0;
  right: -440px;
  width: 100vw;
  height: 100%;
  z-index: 20;
  transition: 0.5s ease-in-out;
  &--active {
    right: 0;
  }
  &--block {
    display: none;
  }
  &__user {
    width: 100%;
    max-width: 440px;
    height: 100%;
    padding: $mpadding * 5 $mpadding $mpadding $mpadding;
    border-radius: 5px;
    background-color: $white;
    box-shadow: $dshadow;
    overflow-y: hidden;
    z-index: 25;
  }
  &__title {
    @include Flex(row, space-between);
  }
  &__sectionTitle {
    font-size: 20px;
    font-weight: lighter;
  }
  &__sectionSubtitle {
    font-size: 15px;
    font-weight: 600;
  }
  &__close {
    @include Flex(row);
    width: 37px;
    height: 37px;
    font-size: 17px;
    color: $primary-color;
    border-radius: 50%;
    border: none;
    background: none;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    &:hover {
      background-color: $primary-color;
      color: white;
    }
  }
  &__body {
    padding-top: $mpadding;
  }
  &__mask {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 100vh;
    z-index: 22;
    &--active {
      width: 100vw;
    }
  }
  &__statisticsValues {
    @include Flex(row, space-between);
    text-align: center;
    padding-top: $mpadding/2;
  }
  &__statisticsItem {
    @include Flex(column, center);
    width: calc(92% / 3);
    height: 75px;
    margin: 0 3px;
  }
  &__activityValues {
    height: 100%;
    margin-top: $mpadding / 2;
    max-height: 350px;
    overflow-y: auto;
    &::-webkit-scrollbar-track {
      background-color: $alto;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $chicago;
    }
  }
  &__statisticsItem,
  &__activityItem {
    padding: 5px;
    background: #f3f3f3;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
  }
  &__activityItem {
    margin-top: $mpadding / 2;
    font-size: 15px;
  }
  &__activityDate {
    text-transform: capitalize;
  }
  &__statisticsTitle {
    font-size: 17px;
    font-weight: lighter;
  }
  &__statisticsValue {
    @include Flex(row, center);
  }
  &__statisticsIcon {
    margin-right: 5px;
  }
  &__activity {
    padding-top: $mpadding;
  }
  @media screen and (min-width: $tablet_width) {
    height: 100vh;
    &__activityValues {
      max-height: 450px;
    }
  }
  @media screen and (min-width: $desktop_width) {
    &__activityValues {
      max-height: 550px;
    }
  }
}
</style>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    state() {
      let { isActive, isOnline } = this.user;
      return isActive && isOnline ? "online" : !isActive && isOnline ? "away" : "offline";
    },
    existUser() {
      return Object.keys(this.user || {}).length > 0;
    },
  },
};
</script>

<template>
  <div class="avatarProfile" v-if="existUser">
    <div class="avatarProfile__avatarContainer" :stateAvatar="state">
      <Avatar class="avatarProfile__avatar" :user="user" />
      <span class="avatarProfile__statusCircle" :stateAvatar="state"></span>
    </div>
    <div class="avatarProfile__userData">
      <p class="avatarProfile__name">{{ user.artisticName }}</p>
      <p class="avatarProfile__role">{{ $global.dictionary.roles_es_auto[user.role] }}</p>
      <div class="avatarProfile__info">
        <span>{{ user.city }}</span>
        <span v-if="user.office">{{ " - " + user.office }}</span>
        <span v-if="user.room">{{ " - " + user.room }}</span>
      </div>
      <i class="fas fa-user-shield"></i>
      <span class="avatarProfile__haveMonitor" v-if="user.monitorAssigned">{{ user.monitorAssigned }}</span>
      <span class="avatarProfile__haveMonitor" v-else>Monitor no asignado</span>
    </div>
  </div>
</template>

<style lang="scss">
.avatarProfile {
  @include Flex(row, flex-start);
  padding-top: $mpadding;
  &__avatarContainer {
    position: relative;
    width: fit-content;
    margin-right: $mpadding;
    border: 3px solid $alto;
    border-radius: 50%;
    &[stateAvatar="online"] {
      border-color: $la_palma;
    }
    &[stateAvatar="away"] {
      border-color: $tree_Poppy;
    }
  }
  &__avatar {
    width: 110px;
    height: 110px;
    font-size: 60px;
    border: 2px solid $white;
  }
  &__statusCircle {
    position: absolute;
    width: 27px;
    height: 27px;
    right: 4px;
    bottom: 4px;
    border: 2px solid $white;
    border-radius: 50%;
    background-color: $alto;
    &[stateAvatar="online"] {
      background-color: $la_palma;
    }
    &[stateAvatar="away"] {
      background-color: $tree_Poppy;
    }
  }
  &__name {
    font-size: 25px;
    font-family: $sec_font;
    color: black;
  }
  &__role {
    text-transform: capitalize;
  }
  &__info {
    font-family: $sec_font;
  }
  &__haveMonitor {
    margin-left: 5px;
    text-transform: uppercase;
    color: black;
  }
  .fas {
    color: $primary-color;
  }
}
</style>
